<template>
    <div>
        <b-card-code title="المواد الامتحانية الدراسية  " no-body>
            <b-card-body>
                <div class="d-flex justify-content-end pb-2">
                    <b-button class="mx-2" size="md" v-b-modal.model-e-w variant="primary"> رفع ملف المواد الامتحانية

                    </b-button>

                    <b-button size="md" @click="hideAllItem()" variant="success"> اخفاء الكل</b-button>
                </div>

                <b-table ref="table" striped hover responsive class="position-relative" :per-page="perPage"
                    :items="items" :fields="fields">
                    <template v-slot:cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" @click="hideItem(data.item.id)" variant="primary"> اخفاء </b-button>
                    </template>
                </b-table>


                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                    prev-class="prev-item" next-class="next-item" class="mb-0" @change="check($event)">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>

            </b-card-body>
        </b-card-code>
        <b-modal id="model-e-w" ok-title="حفظ البيانات" centered size="lg" title="اضافة    المواد الامتحانية"
        no-close-on-backdrop  @hidden="rF" @ok="uploadExamSubject"   @ok.prevent="handleOk">
            <b-form-file @change="selectFile" v-model="file" type="file"
                placeholder="اختر ملف  المواد الدراسية و اسقطه هنا..."
                drop-placeholder="اختر ملف  المواد الدراسية و اسقطه هنا..." />

        </b-modal>
    </div>
</template>
<script>
import { getUserData } from "@/auth/utils";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
    BTable,
    BAvatar,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BForm,
    BFormRow,
    BCol,
    VBModal,
    BBadge,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BFormSelect,
    BPagination, BProgress,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormFile
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    data() {
        return {
            currentPage: 0,
            perPage: 10,
            totalRows: 1,
            pageOptions: [3, 5, 10, 100],

            fields: [
                { key: "index", label: "التسلسل", sortable: true },
                { key: "subject", label: "الموضوع", sortable: true },
                { key: "day", label: "اليوم", sortable: true },
                { key: "time", label: "الوقت", sortable: true },
                { key: "actions", label: "العمليات" }
            ],
            items: [],
            file: null
        };
    },
    mounted() {
        this.getData()
    },
    watch: {
        currentPage: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.getData();
                }
            },
            immediate: true,
        },
    },
    methods: {
        test() { alert('test') },
        async getData() {
            await this.axios
                .get(`exams?take=${this.perPage}&skip=${this.currentPage}`)
                .then((res) => {
                    this.items = res.data.items;
                    this.totalRows = res.data.totalCount;

                    console.log('data :', res.data.items);
                });
        },
        check(e) {
            console.log('e :', e);
            console.log(e)
            this.currentPage = e
        },
        makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
        async uploadExamSubject() {
            this.makeToast(
              "warning",
              "الملف مطلوب",
              "الملف مطلوب (الملف يجب ان يكون   excel )"
            );
            if(this.file){
                let formData = new FormData();
                formData.append('file', this.file);
                await this.axios
                    .post(`exams/import`, formData)
                    .then((res) => {
                        this.$bvModal.hide('model-e-w');
this.makeToast("success", "تم رفع الملف بنجاح", "تم رفع الملف بنجاح");  
                        this.getData()
                        console.log('data :', res.data.items);
                    });
            }   
            else{
                this.$toast.show('يجب اختيار ملف المواد الدراسية', {
                    type: "error",
                    position: "top-center",
                    duration: 3000,
                });
            }
        },
        async hideAllItem() {
            await this.axios
                .get(`exams/hidden`)
                .then((res) => {
                    this.getData()
                    console.log('data :', res.data.items);
                });
        },
        async hideItem(id) {
            await this.axios
                .get(`exams/hidden/${id}`)
                .then((res) => {
                    this.getData()
                    console.log('data :', res.data.items);
                });
        },
        selectFile(e) {
            this.file = e.target.files[0]
        }
    },
    components: {
        BCardCode,
        BTable,
        BForm,
        BAvatar,
        BBadge,
        BFormRow, BProgress,
        BDropdown,
        BDropdownItem,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormCheckboxGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BModal,
        BCol,
        BFormCheckbox,
        BCardText,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormFile,
        ToastificationContent
    },
};
</script>